import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";



const ProtectedRoute = ({ component: Component, ...rest }) => {
    const navigate = useNavigate();
    /*
    Author: Yash
    Date: 01/09/2021
    Use: Added Depedency array to useEffect 
    */
    const session = useSelector((state) => state.session);
    useEffect(() => {
      let isLoggedIn = session?.accessToken;
      if (!isLoggedIn) {
          navigate('/login');
      }
  }, [navigate]);

  return (
    <span>
    <Component />
    </span>
  );
};


ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};
export default ProtectedRoute;