import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as Tick } from "../../../assets/images/icons/Tick.svg";
import { ReactComponent as DisabledTick } from "../../../assets/images/icons/DisabledTick.svg";
const InputText = ({ field }) => {
  const {
    name,
    label,
    value,
    secondValue,
    onChange1,
    onChange2,
    onChange3,
    required,
    error,
    placeholder,
    readOnly,
    tooltip,
    characterLimit,

    icon,
    fieldWidth,
    pattern,
    renderTable,
    errorMsgs,
    secondName,
    actionClicked,
    secondReadOnly,
    isShowRadioBefore,
    thirdName,
    thirdValue,
    firstType,
    secondType,
    thirdType,
    secondoptions,
    onBlur2,
    onBlur1,
    viewOnly
  } = field;

  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    const regex = new RegExp(pattern);

    if (value !== "") {
      if (regex.test(value)) {
        setErrorMsg("");
      } else {
        setErrorMsg(
          errorMsgs
            ? errorMsgs["pattern"]
            : "Plase enter valid value for " + label
        );
      }
    } else if (required && !renderTable) {
      setErrorMsg(errorMsgs ? errorMsgs["required"] : label + " is required");
    }
  }, [value]);

  useEffect(() => {
    const regex = new RegExp(pattern);

    if (secondValue !== "") {
      if (regex.test(secondValue)) {
        setErrorMsg("");
      } else {
        setErrorMsg(
          errorMsgs
            ? errorMsgs["pattern"]
            : "Plase enter valid value for " + label
        );
      }
    } else if (required && !renderTable) {
      setErrorMsg(errorMsgs ? errorMsgs["required"] : label + " is required");
    }
  }, [secondValue]);

  const smplVerificationCondition =
    name.startsWith("svd_stdsizeofsmpl") ||
    name.startsWith("svd_abovesize") ||
    name.startsWith("svd_belowsize");

  return (
    <div className="form-group my-2" style={{ position: "relative" }}>
      {label && (
        <>
          <label htmlFor={name} style={{ width: `${18}%` }}>
            {label}
            <span className="required_mark"> {required ? ` *` : null}</span>
          </label>
          {isShowRadioBefore && (
            <input
              type="checkbox"
              className="tick_icon"
              onChange={onChange3}
              checked={thirdValue}
              disabled={viewOnly}
            />
          )}
        </>
      )}
      <div
        className={
          "w-" +
          (fieldWidth ?? "25") +
          " mx-2" +
          (smplVerificationCondition
            ? " smplVerificationCondition"
            : " double_text")
        }
      >
        <input
          type={firstType || "number"}
          id={name || label}
          name={name || label}
          value={value}
          onChange={onChange1}
          required={required}
          placeholder={placeholder}
          className={"form-control rounded-2 "}
          readOnly={readOnly || viewOnly}
          title={tooltip}
          maxLength={characterLimit}
          pattern={pattern}
          onBlur={onBlur1 ? onBlur1 : null}
        />{" "}
        {secondType == "select" ? (
          <select
            id={secondName || label}
            name={secondName || label}
            value={secondValue}
            placeholder={placeholder}
            className={"form-control rounded-2 doubleInputSelect"}
            readOnly={secondReadOnly || readOnly || viewOnly}
            title={tooltip}
            onChange={onChange2}
            onBlur={onBlur2 ? onBlur2 : null}
          >
            <option value={""}>Select</option>
            {secondoptions.map((option) => (
              <option value={option}>{option}</option>
            ))}
          </select>
        ) : (
          <input
            type={secondType || "text"}
            id={secondName || label}
            name={secondName || label}
            value={secondValue}
            placeholder={placeholder}
            className={"form-control rounded-2 "}
            readOnly={secondReadOnly || readOnly || viewOnly}
            title={tooltip}
            onChange={onChange2}
            
          />
        )}
        {icon ? (
          <i className={"bi bi-" + icon + " text-danger text-bold"}></i>
        ) : (
          <span></span>
        )}
        {errorMsg && actionClicked && (
          <p className="text-danger errorMsg">{errorMsg}</p>
        )}
        {error && actionClicked && <p className="text-danger">{error}</p>}
      </div>
    </div>
  );
};

InputText.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    secondValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    tooltip: PropTypes.string,
    characterLimit: PropTypes.number,
    icon: PropTypes.string,
    fieldWidth: PropTypes.number,
    pattern: PropTypes.string,
    renderTable: PropTypes.func,
    errorMsgs: PropTypes.arrayOf(PropTypes.string),
    secondName: PropTypes.string,
    actionClicked: PropTypes.func,
  }).isRequired,
};
export default InputText;
