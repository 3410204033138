import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { handleInward } from "../commonHandlerFunction/sampleInwardHandlerFunctions";
import PropTypes from "prop-types";

/*
Author  Yash Added PropsTypes as a props validation
Date: 01/09/2021
Use: To validate the props passed to the component
*/
const GroupAssignmentButtons = ({
  setIsPopupOpen,
  setJRFCreationType,
  setInwardBtnchange,
  subTableData,
  formData,
  jrfId,
  handleBackButtonFunction,
  isDisplayNewAddOption,
}) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const translate = t;
  return (
    <div className="submit_btns">
      <Button
        type="button"
        className="cancelBtn"
        id="submit_btn3"
        onClick={() => {
          handleBackButtonFunction();
        }}
      >
        {translate("common.backBtn")}
      </Button>
      <button
        type="button"
        className="saveBtn"
        id="submit_btn2"
        data-name="save"
        disabled={
          subTableData.length === 0 ||
          (formData[0]?.smpl_status !== "inwarded" &&
            formData[0]?.smpl_status !== "assigning")
        }
        onClick={(e) =>
          handleInward(
            "save",
            formData,
            navigate,
            setIsPopupOpen,
            setInwardBtnchange,
            jrfId,
            "assignment"
          )
        }
      >
        {translate("common.saveBtn")}
      </button>
      <button
        type="button"
        className="saveBtn"
        id="submit_btn2"
        data-name="save"
        disabled={subTableData.length === 0}
        onClick={() =>
          navigate(
            "/inwardList/groupAssignmentPreview?status=assignment&sampleInwardId=" +
              formData[1]?.sampleInwardIdMain +
              "&id=" +
              jrfId
          )
        }
      >
        {translate("common.previewBtn")}
      </button>
      <Button
        type="button"
        className="submitBtn"
        id="submit_btn1"
        disabled={subTableData.length === 0 || isDisplayNewAddOption}
        onClick={(e) =>
          handleInward(
            "assign",
            formData,
            navigate,
            setIsPopupOpen,
            setInwardBtnchange,
            jrfId,
            "assignment"
          )
        }
      >
        {translate("common.assignBtn")}
      </Button>
    </div>
  );
};
GroupAssignmentButtons.propTypes = {
  jrfId: PropTypes.string.isRequired,
  setIsPopupOpen: PropTypes.func.isRequired,
  setJRFCreationType: PropTypes.func.isRequired,
  setInwardBtnchange: PropTypes.func.isRequired,
  subTableData: PropTypes.array.isRequired,
  formData: PropTypes.object.isRequired,
};
export default GroupAssignmentButtons;
