export const LoginApi="/login/";
export const LogOutApi="/logout/";
export const forgotPasswordApi="/users/forgot-password/";
export const verifyOtpApi="/verify-otp/";
export const resendOtpApi="/users/resend-otp/";
export const forgotPassOTPcheckApi="/forgotpassotpcheck/";
export const resetPasswordApi="/users/reset-password-confirm/";

export const jrfSearchApi="/jrf/search/"
export const referenceDataApi="/referenceData/";
export const JRFDeleteApi="/jrf/delete";
export const JRFPDFDownloadApi="/jrf_pdf/get";
export const JRFGetApi="/jrf/get";


export const MasterListApi="/masters/list";


export const HistoryApi = "/users/object-history/";
export const sampleDetailsAPI = "/sampleinward/create";
export const sampleInwardDetailsDeleteAPI = "sampleinward/delete";
export const sampleInwardDetailsGetAPI = "/sampleinward/get";
export const sampleInwardDetailsUpdateAPI = "/sampleinward/update";
export const sampleInwardUpdate = "/sampleinward/update";
export const sampleIdsApi = "/sampleids/";

export const allotmentCreateApi="/allotment/create"
export const allotmentUpdateApi="/allotment/update"
export const allotmentDeleteApi="/allotment/delete"
export const getSimgleAllotmentDetailsApi="/allotment/get"

export const sampleverificationdetailCreateApi="/sampleverificationdetail/create"
export const sampleverificationdetailDeleteApi="/sampleverificationdetail/delete/"
export const sampleverificationdetailUpdateApi="/sampleverificationdetail/update"
export const getsamplelabcodeApi="/samplelabcode/get"


export const sampleverificationCreateApi="/sampleverification/create"
export const sampleverificationUpdateApi="/sampleverification/update"
export const sampleverificationDeleteApi="/sampleverification/delete"
export const sampleverificationSingleApi="/sampleverification/get"
export const labparametersApi="/labparameters/get"
export const labGrouoparametersApi="/lmsgrouporparam/get"
export const labGroupsStdBasisApi="/groupstdbasis/get"
export const statuscountApi="/statuscount/"

export const testMemoGetApi="/testmemo/get"
export const testReportGetApi="/testreport/get"
export const testMemoGetSamplesetsApi="/samplesets/get"
export const testMemoGetParambasisstdApi="/parambasisstd/get"
export const testMemoCreateasyncApi="/testmemo/create"
export const testMemoDeleteApi="/testmemo/delete"
export const testMemoUpdateApi="/testmemo/update"
export const geChemistUserApi="/chemistuser/get"

export const SFMCreateApi="/sfm/create"
export const SFMUpdateApi="/sfm/update"
export const SFMDeleteApi="/sfm/delete"
export const SFMGetApi="/sfm/get"
export const SFMSetCountApi="/sfmstatuscount/get"
export const SFMbasisupdateApi="/sfmbasisupdate/update"
export const SFMRejectFlowApi="/sfmrejectflow/"

export const InternalCertificateCreateApi="/internalcertificate/create"
export const InternalCertificateUpdateApi="/internalcertificate/update"
export const InternalCertificateDeleteApi="/internalcertificate/delete"
export const InternalCertificateGetApi="/internalcertificate/get"
export const testReportDetailsApi="/testreportdetails/get"

export const refreshApi="/api/token/refresh/";
export const formulaListapi="/formula/list/";
export const formulagetapi="/formula/get/";
export const formulaCreateapi="/formula/create/";