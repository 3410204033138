import React, { useState } from "react";
import DeleteConfirmation from "./deleteConfirmation";
import PropTypes from "prop-types";
const ActionOptionsTable = ({
  actions,
  onActionHandleClick,
  editableIndex,
  newCreation,
  useFor,
  popupIndex,
  popupMessages,
  saveClicked,
  setPopupIndex,
  tableIndex,
  isCustomSave,
  tableData,
  setEditableIndex,
  getInwardTabledata,
  simpleInwardId,
  moduleType,
  setPopupOpenAssignment,
  InwardCondition,
  setActionName,
  setIsViewOpen,
  setisViewOpen,
  setViewTableData,
  singleData,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [actionType, setActionType] = useState(false);



  const openDeletePopup = () => {
    let headingMsg;
    let titleMsg = "";
    if (actionType == "Edit") {
      onActionHandleClick(actionType);
      return;
    } else if (actionType == "Delete") {
      headingMsg = popupMessages?.delete?.headerMsg;
      titleMsg = popupMessages?.delete?.titleMsg;
    } else {
      headingMsg = popupMessages?.save?.headerMsg;
      titleMsg = popupMessages?.save?.titleMsg;
    }
    return (
      <DeleteConfirmation
        isOpen={isPopupOpen}
        handleClose={setIsPopupOpen}
        handleConfirm={() => {
          onActionHandleClick(actionType);
          setIsPopupOpen(false);
        }}
        popupMessage={titleMsg}
        popupHeading={headingMsg}
        saveClicked={saveClicked}
      />
    );
  };

  const onActionOptionclick = (action) => {
    if (isCustomSave) {
      setPopupIndex((prevIndex) =>
        prevIndex === tableData.length ? -1 : tableData.length
      );
      if (!InwardCondition) {
        setIsPopupOpen(true);
      } else {

        setPopupOpenAssignment(true);
      }
      setEditableIndex("");
      setActionType("customSave");
      setActionName("customSave");
    } else if (action.text === "Cancel") {
      if (moduleType !== "sampleverification") {
        getInwardTabledata(simpleInwardId);
      }

      setPopupIndex("");
      setEditableIndex("");
    } else if (action.text === "Edit" || action.text === "Save") {
      if (InwardCondition) {
        setActionName("Save");

        setPopupOpenAssignment(true);
        setEditableIndex(tableIndex);
        setPopupIndex(tableIndex);
      } else {
        setEditableIndex(tableIndex);
        setPopupIndex(tableIndex);
        setActionType(action.text);
        setIsPopupOpen(true);
      }
    }
    else if (action.text === "View") {
      setIsViewOpen(true)
      if (moduleType === "sampleverification") {
        setViewTableData(singleData.params_and_standards)
      }
    }
    else {
      setEditableIndex("");
      setPopupIndex(tableIndex);
      setActionType(action.text);
      setIsPopupOpen(true);
    }
  }

  return (
    <div className="actionOptions">
      {actions.map((action, actionIndex) => (
        <button
          type="button"
          key={"action Options" + actionIndex}
          onClick={() => onActionOptionclick(action)}
          className={
            InwardCondition && action.text === "Save"
              ? "submitBtn moreWidth pdAdjust"
              : "invisible-button"
          }
          aria-label="action-button"
        >
          {!InwardCondition ? (
            <i className={action.icon} title={action.text}></i>
          ) : action.text === "Save" && InwardCondition ? (
            "Add Sample +"
          ) : (
            <i className={action.icon} title={action.text}></i>
          )}
        </button>
      ))}
      {isPopupOpen && openDeletePopup()}
    </div>
  );
};

ActionOptionsTable.propTypes = {
  actions: PropTypes.array.isRequired,
  onActionHandleClick: PropTypes.func.isRequired,
  editableIndex: PropTypes.number,
  newCreation: PropTypes.bool,
  useFor: PropTypes.string,
  popupIndex: PropTypes.number,
  popupMessages: PropTypes.array,
  saveClicked: PropTypes.bool,
  setPopupIndex: PropTypes.func.isRequired,
  tableIndex: PropTypes.number,
  isCustomSave: PropTypes.bool,
  tableData: PropTypes.array,
  setEditableIndex: PropTypes.func.isRequired,
  getInwardTabledata: PropTypes.func.isRequired,
  simpleInwardId: PropTypes.string,
  moduleType: PropTypes.string,
};

export default ActionOptionsTable;
