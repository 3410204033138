import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  handleFormPost,
  handleFormSave,
} from "../commonHandlerFunction/JRFHandlerFunctions";
import React from "react";
import PropTypes from 'prop-types';
const JRFButtons = ({ setIsPopupOpen, setJRFCreationType, handleSubmit,viewOnly,handleBackButtonFunction }) => {
  const { t } = useTranslation();
  const translate = t;
  return (
    <div className="submit_btns">
      {!viewOnly && (
        <React.Fragment>
          <Button
            type="button"
            className="cancelBtn"
            id="submit_btn3"
            onClick={() => {
              handleBackButtonFunction()
            }}
          >
            {translate("common.backBtn")}
          </Button>
          <button
            type="button"
            className="saveBtn"
            id="submit_btn2"
            data-name="save"
            onClick={(e) =>
              handleFormSave(
                e,
                handleSubmit,
                setIsPopupOpen,
                setJRFCreationType
              )
            }
          >
            {translate("common.saveBtn")}
          </button>
          <Button
            type="button"
            className="submitBtn"
            id="submit_btn1"
            onClick={(e) =>
              handleFormPost(
                e,
                handleSubmit,
                setJRFCreationType,
                setIsPopupOpen
              )
            }
          >
            {translate("common.postBtn")}
          </Button>
        </React.Fragment>
      )}
    </div>
  );
};


JRFButtons.propTypes = {
  setIsPopupOpen: PropTypes.func.isRequired,
  setJRFCreationType: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool.isRequired,
};

export default JRFButtons;
