import React, { useEffect } from "react";
import RenderFields from "../RenderFields";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  NavItem,
  NavLink,
  Col,
} from "react-bootstrap";
import OverlayLoading from "../OverlayLoading";
const ModalInward = ({
  section,
  sectionIndex,
  formData,
  handleFieldChange,
  formErrors,
  tableData,
  updatedMasterOptions,
  setPopupOpenAssignment,
  onActionHandleClick,
  actionName,
  handleCloseInwardPopup,
  editableIndex,
  isBtnclicked,
  isOverlayLoader
}) => {
  const InwardUnits = formData[1]?.jrf_commodity_detail?.cmd_unit || [];
  let unitoptions = [];
  InwardUnits.map((singleOpt) => {
    unitoptions.push({
      name: singleOpt.cu_name,
      id: singleOpt.cu_symbol,
    });
  });
  if (InwardUnits.length === 0) {
    unitoptions.push({
      name: "GM",
      id: "gm",
    });
  }
  const getCustomCellValues = (cell) => {
    if (cell.name == "smpl_detail_smpl_qty_unit") {
      // cell.customOptions = unitoptions;
      // cell.isCustomOptions = true;
      cell.type="text"

    }
    if (cell.name == "smpl_detail_dos") {
      cell.viewOnly = true;

    } else if (cell.name === "smpl_detail_seal_number") {

      if (
        formData[1]?.[
        "smpl_detail_pkging_condition" +
        "_" +
        (actionName === "Save" ? editableIndex : tableData.length)
        ] === "Unsealed"
      ) {
        cell.required = false;
        cell.readOnly = true;

      } else {
        cell.required = true;
        cell.readOnly = false;
      }
    }
    return cell;
  };
  return (
    <div className="popupSearchContainerBG">
      {isOverlayLoader && <OverlayLoading />}
      <div className="popupInwardModal popupWidthAdjustmentInward">
        <div className="rejectSearchCross">
          <button
            onClick={handleCloseInwardPopup}
            className="nonNativeButton2"
            aria-label="Reject Button"
          >
            <i className="bi bi-x-lg h4"></i>
          </button>
        </div>

        {section.rows.map((row, rowIndex) => (
          <>
            {/* <td>{rowIndex + 1 + tableData.length}</td> */}
            <Row className="autoWidthImportant">
              <h2 className="modalHeader">Sample Inward Details</h2>
              {section.headers.map((cell, cellIndex) => (
                <div
                  className={"col-md-" + cell?.width}
                  key={"Modal-Header-" + cellIndex}
                >
                  <RenderFields
                    field={getCustomCellValues(cell)}
                    sectionIndex={sectionIndex}
                    fieldIndex={rowIndex * 100 + cellIndex}
                    formData={formData}
                    handleFieldChange={handleFieldChange}
                    formErrors={formErrors} // Pass formErrors to RenderFields
                    ///for render table only
                    renderTable={true}
                    tableIndex={rowIndex}
                    customName={
                      cell.name +
                      "_" +
                      (actionName === "Save" ? editableIndex : tableData.length)
                    }
                    masterOptions={updatedMasterOptions}
                    // from="Table"
                    upperClass="popupUpperClass"
                  />
                </div>
              ))}
            </Row>
          </>
        ))}
        <div className="popupInwardButtonsContainer">
          <div className="popupSearchButtons">
            <button type="button" onClick={handleCloseInwardPopup}>
              Cancel
            </button>
            <button
              type="button"
              disabled={isBtnclicked}
              onClick={() => {
                onActionHandleClick(actionName);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalInward;
