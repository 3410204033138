import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { changeTestMEmoStatuChange } from "../commonHandlerFunction/testMemoFunctionHandler";
import PropTypes from "prop-types";
import React from "react";

const TestMemoButtons = ({
  setIsPopupOpen,
  setJRFCreationType,
  setInwardBtnchange,
  viewOnly,
  role,
  setIsRejectPopupOpen,
  testMemoId,
  pageType,
  handleBackButtonFunction,
  setIsOverlayLoader
}) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const translate = t;
  return (
    <div className="submit_btns">
      {" "}
      <Button
        type="button"
        className="saveBtn"
        id="submit_btn3"
        onClick={() => {
          handleBackButtonFunction();
        }}
      >
        {translate("common.backBtn")}
      </Button>
      {!viewOnly &&
        (pageType == "verifytestresult" ? (
          <>
            <Button
              type="button"
              className="submitBtn"
              id="submit_btn1"
              onClick={() =>
                changeTestMEmoStatuChange(testMemoId, navigate, "verified",'', setIsOverlayLoader)
              }
            >
              {translate("common.verifyBtn")}
            </Button>
            <Button
              type="button"
              className="rejectBtn"
              id="submit_btn1"
              onClick={(e) => setIsRejectPopupOpen(true)}
            >
              {translate("common.rejectBtn")}
            </Button>
          </>
        ) : pageType === "verifytestmemo" ? (
          <>
            <Button
              type="button"
              className="rejectBtn"
              id="submit_btn1"
              onClick={(e) => setIsRejectPopupOpen(true)}
            >
              {translate("common.rejectBtn")}
            </Button>
            <Button
              type="button"
              className="submitBtn"
              id="submit_btn1"
              onClick={() =>
                changeTestMEmoStatuChange(testMemoId, navigate, "posted", '', setIsOverlayLoader)
              }
            >
              {translate("common.acceptBtn")}
            </Button>
          </>
        ) : pageType == "Edit" ? (
          <button
            type="button"
            className="submitBtn"
            id="submit_btn3"
            data-name="save"
            onClick={() => {
              setInwardBtnchange("reassign");
              setIsPopupOpen(true);
            }}
          >
            Reassign
          </button>
        ) : (
          <button
            type="button"
            className="submitBtn"
            id="submit_btn3"
            data-name="save"
            onClick={() => {
              setInwardBtnchange("sendToLab");
              setIsPopupOpen(true);
            }}
          >
            {translate("common.sendLabBtn")}
          </button>
        ))}
    </div>
  );
};
TestMemoButtons.propTypes = {
  setIsPopupOpen: PropTypes.func.isRequired,
  setJRFCreationType: PropTypes.func.isRequired,
  setInwardBtnchange: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  setIsRejectPopupOpen: PropTypes.func.isRequired,
  testMemoId: PropTypes.string.isRequired,
};
export default TestMemoButtons;
