import React from "react";
import PropTypes from 'prop-types';
const DropDown = ({ field }) => {
  const { name, label, options } = field;

  return (
    <div className="form-group my-2">
      <div className="select-entity">
      {label && (
        <label htmlFor={name} style={{ width: `${18}%` }}>
          {label}
          <span className="required_mark"> {field.required ? ` *` : null}</span>
        </label>
      )}

        <p>{options.join(" , ")}</p>
      </div>
    </div>
  );
};


DropDown.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
  }).isRequired,
};

export default DropDown;
