import CryptoJS from "crypto-js";

// Define your secret key (ensure it's 32 bytes long)
// const secretKey ='TCRC&$LMSAPP2024';
// const secretKey = process.env.REACT_APP_SECRET_KEY;
const secretKey = "TCRC&$LMSAPP2024";
// const secretKey = "pktRiH+iRxI2Gw8G/6qjyzZQVpxzOnC9IOqSQ1fkkxk=";
const encryptData = (data) => {
  
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const iv = CryptoJS.lib.WordArray.random(16);

  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  // Combine IV and encrypted data
  const ivAndCiphertext = iv
    .concat(encrypted.ciphertext)
    .toString(CryptoJS.enc.Base64);
    console.log("datadata", data, secretKey,ivAndCiphertext);
  return ivAndCiphertext;
};

const decryptData = (encryptedData) => {
  try{
    if (typeof encryptedData !== "string") {
      throw new TypeError("encryptedData should be a Base64 encoded string");
    }
    const encryptedDataBytes = CryptoJS.enc.Base64.parse(encryptedData);
    const iv = CryptoJS.lib.WordArray.create(
      encryptedDataBytes.words.slice(0, 4)
    );
    const encryptedMessage = CryptoJS.lib.WordArray.create(
      encryptedDataBytes.words.slice(4)
    );
  
    const key = CryptoJS.enc.Utf8.parse(secretKey);
  
    const decrypted = CryptoJS.AES.decrypt(
      {
        ciphertext: encryptedMessage,
      },
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const decryptedData = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    console.log('decryptedData',decryptedData)
    return decryptedData;
  }
  catch(ex){
    console.log('exx',ex)
  }
};
const encryptDataForURL = (plainTextOrNumber) => {
  if(!plainTextOrNumber){
    return '';
  }
  const plainText = plainTextOrNumber.toString(); // Convert to string if it's a number
    const ciphertext = CryptoJS.AES.encrypt(plainText, secretKey).toString();
    
    // Make it URL-safe by replacing characters
    const base64Ciphertext = ciphertext.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return base64Ciphertext;
};

const decryptDataForURL = (ciphertext) => {
  if(!ciphertext){
    return ""
  }
  const base64Ciphertext = ciphertext.replace(/-/g, '+').replace(/_/g, '/');
    
    // Decrypt the string
    const bytes = CryptoJS.AES.decrypt(base64Ciphertext, secretKey);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    
    return decryptedText;
};
export { encryptData, decryptData,encryptDataForURL,decryptDataForURL };

// const AesUtilService = () => {
//   // const salt = CryptoJS.lib.WordArray.create([-57, -75, -103, -12, 75, 124, -127, 119]);
//   const salt = 'f2677cd28ebfde8c589a9c82c52207fa'; // 32 bytes

//   const keySize = 256;
//   const iterations = 65536;
//   const pass = 'Subscripti0nP@SSphrase!';

//   // Encrypt function
//   const encrypt = (plaintext) => {
//     // const key = CryptoJS.enc.Base64.parse(keyBase64);

//     const key = 'TCRC#$LMSAPP2024';

//     const data = CryptoJS.enc.Utf8.parse(plaintext);
//     const iv = CryptoJS.lib.WordArray.random(16); // Generate a 16-byte IV
//     console.log('IV length:', iv.sigBytes); // Log the IV length

//     console.log('iv', iv);
//     console.log('key', key);
//     const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Hex.parse(key), {
//       iv: iv,
//       padding: CryptoJS.pad.Pkcs7
//     });
//     console.log('cipher', encrypted);
//     const encryptData = {
//       iv: CryptoJS.enc.Base64.stringify(iv), // Convert IV to Base64 string for transmission
//       data: encrypted.toString()
//     }
//     return encryptData;
//     // Encrypt data
//     // const iv = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Base64);
//     // const key = CryptoJS.lib.WordArray.random(32).toString(CryptoJS.enc.Base64);
//     // const encrypted = CryptoJS.AES.encrypt(plaintext, key, { iv: iv, padding: CryptoJS.pad.Pkcs7 });
//     // const ciphertext = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);

//     // Calculate hash for data integrity verification
//     const hash = CryptoJS.SHA256(plaintext).toString(CryptoJS.enc.Base64);

//     // Send encrypted data and hash to backend
//     const requestData = { ciphertext: encrypted, hash: hash };
//     return requestData;
//   };
//   // const encrypt = (msg) => {
//   //   const key = CryptoJS.PBKDF2(pass, salt, {
//   //     keySize: keySize / 32,
//   //     iterations: iterations
//   //   });
//   //   CryptoJS.lib.WordArray.random(32);
//   //   console.log('key ff', key);
//   //   const iv = CryptoJS.lib.WordArray.random(16);
//   //   console.log('iv ff', iv);
//   //   const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(msg), key, {
//   //     iv: iv,
//   //     padding: CryptoJS.pad.Pkcs7,
//   //     mode: CryptoJS.mode.CBC
//   //   });

//   //   const ciphertext = iv.concat(encrypted.ciphertext);

//   //   return CryptoJS.enc.Base64.stringify(ciphertext);
//   // };

//   const decrypt = (transitmessage) => {
//     try {
//       console.log('transitmessage', transitmessage);
//       const iv = CryptoJS.enc.Base64.parse(transitmessage.substr(0, 24)); // Parse IV from the encrypted message
//       const encrypted = transitmessage.substring(24);
//       console.log('iv', iv);
//       console.log('encrypted', encrypted);
//       const key = CryptoJS.PBKDF2(pass, salt, {
//         keySize: keySize / 32,
//         iterations: iterations
//       });
//       console.log('key', key);
//       const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
//         iv: iv,
//         padding: CryptoJS.pad.Pkcs7,
//         mode: CryptoJS.mode.CBC
//       });
//       console.log('decrypted util', decrypted);
//       console.log('decrypted util 11', decrypted.toString(CryptoJS.enc.Utf8));
//       return decrypted.toString(CryptoJS.enc.Utf8);
//     } catch (error) {
//       console.error('Error decrypting data:', error);
//       return null;
//     }
//   };

//   return {
//     encrypt,
//     decrypt
//   };
// };

// export default AesUtilService;
