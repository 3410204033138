import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteDataFromApi,
  postDataFromApi,
  putDataFromApi,
} from "../../services/commonServices";
import {
  getFormatedDate,
  isModuelePermission,
} from "../../services/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmation from "./deleteConfirmation";
import {
  InternalCertificateDeleteApi,
  JRFDeleteApi,
  JRFPDFDownloadApi,
  SFMDeleteApi,
  allotmentDeleteApi,
  sampleInwardDetailsDeleteAPI,
  sampleverificationDeleteApi,
  testMemoDeleteApi,
} from "../../services/api";
import { historyData } from "../../actions/authActions";
import PropTypes from "prop-types";

const PopupOptions = ({
  popupActions,
  setPopupIndex,
  id,
  row,
  section,
  getAllListingData,
  formConfig,
  type,
  sampleInwardFormId,
  model,
  isBottom,
  status,
  setDontClick,
}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  // const { rolePermissions, user } = useSelector((state) => state);
  let user;
  const session = useSelector((state) => state.session);
  user = session?.user;
  
  let rolePermissions;
  rolePermissions = session?.user?.permissions;
  
  const [actions, setActions] = useState(popupActions);
  const [isDelete, setIsDelete] = useState(false);
  const [actionType, setActionType] = useState(false);


  const handleClick = async (value, actionType = "") => {
    setActionType(value);
    setDontClick(true);

    if (formConfig?.listView?.moduleType === "sampleinward") {
      if (actionType === "View") {
        navigate(
          `/inwardList/inwardForm?status=${value}&id=${id}&sampleInwardId=${sampleInwardFormId}&action=View`
        );
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "Inward No " + row.smpl_inward_number,
          model: model,
          redirect: "/inwardList",
          Breadcrumb: "Sample Inward List",
        };
        dispatch(historyData(historyDetails));
        navigate(`/module-history?status=${value}&id=${row["smpl_inwrd_id"]}`);

        setPopupIndex(-1);
      } else if (value === "assignmentview") {
        navigate(
          "/inwardList/groupAssignmentPreview?status=assignment&sampleInwardId=" +
            sampleInwardFormId +
            "&id=" +
            id
        );
      } else if (value === "Delete") {
        setIsDelete(true);
      } else {
        navigate(
          `/inwardList/inwardForm?status=${value}&id=${id}&sampleInwardId=${sampleInwardFormId}&action=Edit`
        );
      }
    } else if (formConfig?.listView?.moduleType === "testmemomain") {
      if (value === "Delete") {
        setIsDelete(true);
      } else if (value === "View") {
        navigate(
          `/testmemoList/testmemo?view=view&status=testMemo&testMemoId=${row["tm_id"]}`
        );
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "Test Memo No " + row.tm_number,
          model: model,
          redirect: "/testmemoList",
          Breadcrumb: "Test Memo List",
        };
        dispatch(historyData(historyDetails));
        navigate(`/module-history?status=${value}&id=${row["tm_id"]}`);

        setPopupIndex(-1);
      } else {
        navigate(`/testmemoList`);
      }
    } else if (formConfig?.listView?.moduleType === "allotment") {
      if (value === "View") {
        navigate(
          `/allotmentList/allotment?view=view&status=allotment&sampleAllotmentId=${row["sa_id"]}`
        );
      } else if (value === "Delete") {
        setIsDelete(true);
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "Allotment No " + row.tm_number,
          model: model,
          redirect: "/allotmentList",
          Breadcrumb: "Allotment List",
        };
        dispatch(historyData(historyDetails));
        navigate(`/module-history?status=${value}&id=${row["sa_id"]}`);

        setPopupIndex(-1);
      } else {
        navigate(`/testmemoList`);
      }
    } else if (formConfig?.listView?.moduleType === "sampleverification") {
      if (value === "View") {
        navigate(
          `/verificationList/sampleVerification?view=view&status=verification&sampleVarificationId=${row["sv_id"]}`
        );
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "Verification No " + row.sv_verificationno,
          model: model,
          redirect: "/verificationList",
          Breadcrumb: "Verification List",
        };
        dispatch(historyData(historyDetails));
        navigate(`/module-history?status=${value}&id=${row["sv_id"]}`);

        setPopupIndex(-1);
      } else if (value === "Delete") {
        setIsDelete(true);
      } else {
        navigate(`/testmemoList`);
      }
    } else if (formConfig?.listView?.moduleType === "sfm") {
      if (value === "View") {
        navigate(
          `/SFMList/result?view=view&status=SFMResult&sfmid=${row["sfm_id"]}`
        );
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "SFM No " + row.sfm_msfm_no,
          model: model,
          redirect: "/SFMList",
          Breadcrumb: "SFM List",
        };
        dispatch(historyData(historyDetails));
        navigate(`/module-history?status=${value}&id=${row["sfm_id"]}`);

        setPopupIndex(-1);
      } else if (value === "Delete") {
        setIsDelete(true);
      } else {
        navigate(`/testmemoList`);
      }
    } else if (formConfig?.listView?.moduleType === "internalcertificate") {
      if (value === "View") {
        navigate(
          `/testmemoList/test-results?view=view&status=testmemoresult&editId=${row["ic_id"]}`
        );
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "ULR No " + row.ic_ulrno,
          model: model,
          redirect: "/testReport",
          Breadcrumb: "Test Report List",
        };
        dispatch(historyData(historyDetails));
        navigate(`/module-history?status=${value}&id=${row["ic_id"]}`);

        setPopupIndex(-1);
      } else if (value === "Edit") {
        navigate(
          `/testmemoList/test-results?status=testmemoresult&editId=${row["ic_id"]}`
        );
      } else if (value === "Delete") {
        setIsDelete(true);
      } else if (value === "Preview Report") {
        navigate(`/testReport/preview/${row["ic_id"]}`);
      } else {
        navigate(`/testmemoList`);
      }
    } else if (formConfig?.listView?.moduleType === "jrf") {
      
      if (value === "Delete") {
        setIsDelete(true);

      } else if (value === "Download") {
        // setIsDelete(true);
        navigate(`/jrfListing/jrf-pdf-preview/${id}`);
        setPopupIndex(-1);
      } else if (value === "History") {
        const historyDetails = {
          recordNo: "JRF No " + row.jrf_no,
          model: model,
          redirect: "/jrfListing",
          Breadcrumb: "JRF List",
        };

        dispatch(historyData(historyDetails));
        navigate(`/module-history?status=${value}&id=${id}`);

        setPopupIndex(-1);
      } else {
        if(row.jrf_is_external){
          navigate(`/jrfListing/external-jrf?status=${value}&id=${id}`);
        }
        else{
          navigate(`/jrfListing/jrf?status=${value}&id=${id}`);
        }
        setPopupIndex(-1);
      }
    }
  };

  const handleDeleteData = async () => {
    if (formConfig?.listView?.moduleType === "sampleinward") {
      handleSimpleInwardDeleteData();
      return;
    } else if (formConfig?.listView?.moduleType === "testmemomain") {
      handleTestMemoDeleteData();
      return;
    } else if (formConfig?.listView?.moduleType === "allotment") {
      handleAllotmentDelete();
      return;
    } else if (formConfig?.listView?.moduleType === "sampleverification") {
      handleSampleVerificationDelete();
      return;
    } else if (formConfig?.listView?.moduleType === "sfm") {
      handleSFMDelete();
      return;
    } else if (formConfig?.listView?.moduleType === "internalcertificate") {
      handleinternalcertificateDelete();
      return;
    } else {
      let deleteBody = {
        jrf_id: id,
      };
      let res = await deleteDataFromApi(JRFDeleteApi, deleteBody);
      if (res?.data?.status === 200) {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setIsDelete(false);
      setPopupIndex(-1);
      getAllListingData();
    }
  };
  const handleSimpleInwardDeleteData = async () => {
    let res;
    if (row["status"] == "rejected") {
      let MainData = {
        jrf_id: id,
        jrf_data: {
          jrf_status: "cancelled",
        },
      };
      res = await putDataFromApi(formConfig.apiEndpoints.update, MainData);
    } else {
      let deleteBody = {
        smpl_inwrd_id: sampleInwardFormId,
      };
      res = await deleteDataFromApi(sampleInwardDetailsDeleteAPI, deleteBody);
    }
    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleTestMemoDeleteData = async () => {
    let deleteBody = {
      test_memo_id: row.tm_id,
    };
    let res = await deleteDataFromApi(testMemoDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleAllotmentDelete = async () => {
    let deleteBody = {
      sa_id: row.sa_id,
    };
    let res = await deleteDataFromApi(allotmentDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleSampleVerificationDelete = async () => {
    let deleteBody = {
      sv_id: row.sv_id,
    };
    let res = await deleteDataFromApi(sampleverificationDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleSFMDelete = async () => {
    let deleteBody = {
      sfm_id: row.sfm_id,
    };
    let res = await deleteDataFromApi(SFMDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleinternalcertificateDelete = async () => {
    let deleteBody = {
      ic_id: row.ic_id,
    };
    let res = await deleteDataFromApi(InternalCertificateDeleteApi, deleteBody);

    if (res?.data?.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsDelete(false);
    setPopupIndex(-1);
    getAllListingData();
  };
  const handleDownloadJRF = async () => {
    let bodyData = {
      jrf_id: id,
    };
    let res = await postDataFromApi(JRFPDFDownloadApi, bodyData, "", 1);
    if (res?.status === 200) {
      let pdfDate = "JRF" + id + "_" + getFormatedDate(new Date(), "", 1);
      const blob = new Blob([res.data], { type: "application/pdf" });

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = pdfDate + ".pdf"; // Name for the downloaded file

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to start the download
      link.click();

      // Clean up the link after the download
      document.body.removeChild(link);
    }
    setIsDelete(false);
    setPopupIndex(-1);
  };
  // useEffect(() => {
    // if (
    //   row.jrf_status === "posted" ||
    //   row.jrf_status === "accepted" ||
    //   row.jrf_status === "tasked"
    // ) {
    //   const filteredAction = actions.filter((item) => item.value !== "Edit");
    //   setActions(filteredAction);
    // } else if (row.jrf_status === "rejected") {
    //   const filteredAction = actions.filter((item) => item.value !== "Delete");
    //   setActions(filteredAction);
    // } else if (row.jrf_status === "completed") {
    //   const filteredAction = actions.filter(
    //     (item) => item.value !== "Edit" && item.value !== "Delete"
    //   );
    //   setActions(filteredAction);
    // }
    /*
    Author : Yash Darshankar
    Date : 25/06/2021
    Description : Below code is used to hide download button
    */
    // if (row.jrf_status === "posted") {
    //   const filteredAction = actions.filter(
    //     (item) => item.value !== "Delete" && item.value !== "Download"
    //   );
    //   setActions(filteredAction);
    // } else if (row.jrf_status === "saved") {
    //   const filteredAction = actions.filter(
    //     (item) => item.value !== "Download"
    //   );
    //   setActions(filteredAction);
    // }
  // }, []);
  const openDeletePopup = () => {
    let headingMsg = "Confirmation!";
    let titleMsg = "";
    if (actionType == "Delete") {
      headingMsg = formConfig?.popupMessages?.delete?.headerMsg;
      titleMsg = formConfig?.popupMessages?.delete?.titleMsg;
    } else if (actionType == "Download") {
      headingMsg = formConfig?.popupMessages?.download?.headerMsg;
      titleMsg = formConfig?.popupMessages?.download?.titleMsg;
    }
    return (
      <DeleteConfirmation
        isOpen={isDelete}
        handleClose={setIsDelete}
        handleConfirm={() =>
          actionType == "Download" ? handleDownloadJRF() : handleDeleteData()
        }
        popupMessage={titleMsg}
        popupHeading={headingMsg}
      />
    );
  };
  const chkActionVisibility = (
    rolePermissions,
    module,
    permission,
    value = ""
  ) => {
    let isVisbile = false;
    if (
      permission &&
      isModuelePermission(rolePermissions, module, permission)
    ) {
      if (module == "jrf") {
        if (value.toLowerCase() === "edit") {
          let data = {
            BU: ["saved", "rejected"],
            LR: ["posted", "accepted"],
          };
          if (data[user?.role].includes(row.jrf_status)) {
            isVisbile = true;
          }
          else if(user?.role==="LR" && row.jrf_status==="saved"){
            isVisbile = true;
          }
        } else if (value.toLowerCase() === "delete") {
          let data = {
            BU: ["saved", "rejected"],
            LR: [],
          };
          if (data[user?.role].includes(row.jrf_status)) {
            isVisbile = true;
          }
        } else {
          isVisbile = true;
        }
      } else if (module == "sampleinward") {
        if (value.toLowerCase() === "edit") {
          let data = {
            LR: ["created", "saved"],
          };
          if (data[user?.role].includes(row.smpl_status)) {
            isVisbile = true;
          }
        } else {
          isVisbile = true;
        }
      } else if (module == "internalcertificate") {
        if (value.toLowerCase() === "edit") {
          let data = {
            LR: ["rejected", "saved"],
            TM: [],
            DTM: [],
            LC: [],
          };
          if (data[user?.role].includes(row.status)) {
            isVisbile = true;
          }
        } else {
          isVisbile = true;
        }
      }
      else{
        isVisbile = true;
      }
    } else {
      if (value.toLowerCase() === "download") {
        let data = {
          BU: ["posted", "saved"],
          LR: ["posted", "saved"],
        };
        if (!data[user?.role].includes(row.jrf_status)) {
          isVisbile = true;
        }
      } else if (value.toLowerCase() === "history") {
        isVisbile = true;
      }
    }
    return isVisbile;
  };
  return (
    <div className={"popupOptions" + (isBottom ? " IsBottom" : "")}>
      {actions.map((action, actionIndex) => {
        return action.value &&
          action.value !== "" &&
          action.type === "icon" &&
          action.status !== "assignmentview" &&
          chkActionVisibility(
            rolePermissions,
            section.moduleType,
            action.permission,
            action.value
          ) ? (
          <div key={"sample-inward-" + actionIndex}>
            <button
              className="nonNativeButton2"
              aria-label="Sample Inward Action Button"
              onClick={() =>
                formConfig?.listView?.moduleType === "sampleinward"
                  ? handleClick(action.status, action.value)
                  : handleClick(action.value)
              }
            >
              <i className={action.label}></i>
              {action.value}
            </button>
          </div>
        ) : action.status === "assignmentview" &&
          row["smpl_status"] === "assigned" ? (
          <div key={"actionIndex-" + actionIndex}>
            <button
              className="nonNativeButton2"
              aria-label="Assignment View Button"
              onClick={() =>
                formConfig?.listView?.moduleType === "sampleinward"
                  ? handleClick(action.status, action.value)
                  : handleClick(action.value)
              }
            >
              <i className={action.label}></i>
              {action.value}
            </button>
          </div>
        ) : null;
      })}
      {isDelete && openDeletePopup()}
    </div>
  );
};

PopupOptions.propTypes = {
  popupActions: PropTypes.array.isRequired,
  setPopupIndex: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired,
  getAllListingData: PropTypes.func.isRequired,
  formConfig: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  sampleInwardFormId: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
};

export default PopupOptions;
