import React, { useEffect, useState } from "react";
import RenderFields from "./RenderFields";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash.svg";

import ActionOptionsTable from "./actionOptionsTable";
import { getSelectedOptionName } from "../../services/commonFunction";
import {
  labGroupsStdBasisApi,
  sampleDetailsAPI,
  sampleInwardDetailsGetAPI,
} from "../../services/api";
import { postDataFromApi } from "../../services/commonServices";
import { toast } from "react-toastify";
import { assignmentPageHandleAction } from "./commonHandlerFunction/groupAssignmentFunctions";
import PropTypes from "prop-types";
import Loader from "./OverlayLoading";
import DeleteConfirmation from "./deleteConfirmation";
// import Loader from "./Loading";

const RenderAssignmentTableSection = ({
  section,
  sectionIndex,
  formData,
  handleFieldChange,
  addRow,
  deleteRow,
  deleteColumn,
  formErrors,
  setFormData,
  popupMessages,
  pageType,
  action,
  masterOptions,
  saveClicked,
  moduleType,
  setTableData,
  getAssignmentMasterData,
  setSaveClicked,
  tableData,
  getSampleIdsMasterData,
  setIsDisplayNewAddOption,
  isDisplayNewAddOption,
  setIsOverlayLoader,
  isOverlayLoader
}) => {
  let EditAction = [
    {
      icon: "bi bi-floppy-fill",
      text: "Save",
    },
    {
      icon: "bi bi-x-circle-fill",
      text: "Cancel",
    },
  ];
  let MainAction = [
    {
      icon: "bi bi-pencil-square",
      text: "Edit",
    },
    {
      icon: "bi bi-trash3-fill",
      text: "Delete",
    },
  ];
  if (pageType == "assignment") {
    MainAction.splice(0, 1);
  }
  if (moduleType === "sampleverification") {
    MainAction.splice(1, 1);
  }
  const initialCustomData = {
    [sectionIndex]: {
      smpl_set_basisjson: [],
      smpl_set_smpljson: [],
      is_group_param: "Group",
      is_group_param_name: "Group",
      smpl_set_paramjson: "",
      smpl_set_testmethodjson: "",
      smpl_set_unit: "",
    },
  };
  const [sampleDataTable, setSampleDataTable] = useState([]);
  const [updatedMasterOptions, setUpdatedMasterOptions] = useState([]);
  const [customFormData, setCustomFormData] = useState(initialCustomData);
  const [parameterDataTable, setParameterDataTable] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [popupIndex, setPopupIndex] = useState(-1);
  const [editableIndex, setEditableIndex] = useState("");
  const [simpleInwardId, setSimpleInwardId] = useState("");
  const [groupParameteres, setGroupParameters] = useState([]);
  const [isGroupChanged, setIsGroupChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [finalParamDataSort, setFinalParamDataSort] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      setSimpleInwardId(formData[1]?.sampleInwardIdMain);
      if (formData[1]?.sampleInwardIdMain) {
        getInwardTabledata(formData[1]?.sampleInwardIdMain);
      }
    }, 1000);
  }, [formData[1]?.sampleInwardIdMain]);

  useEffect(() => {
    setUpdatedMasterOptions(masterOptions);
  }, [masterOptions]);
  useEffect(() => {
    const getOnloadFunction = async () => {
      if (
        customFormData[sectionIndex].is_group_param &&
        formData[1]?.sampleInwardIdMain
      ) {
        var newinitialCustomData = initialCustomData;
        newinitialCustomData[sectionIndex].is_group_param =
          customFormData[sectionIndex].is_group_param;
        newinitialCustomData[sectionIndex].smpl_set_smpljson_name =
          customFormData[sectionIndex].smpl_set_smpljson_name;
        newinitialCustomData[sectionIndex].smpl_set_smpljson =
          customFormData[sectionIndex].smpl_set_smpljson;
        newinitialCustomData[sectionIndex].smpl_set_unit =
          customFormData[sectionIndex].smpl_set_unit;
        setCustomFormData(newinitialCustomData);
        setGroupParameters([]);
        setIsLoading(true);
        var isCalled = await getAssignmentMasterData(
          formData[1].jrf_commodity,
          formData[1].jrf_lab,
          customFormData[sectionIndex].is_group_param.toLowerCase()
        );
        if (isCalled) {
          setIsLoading(false);
        }
      }
    };
    getOnloadFunction();
  }, [customFormData[sectionIndex].is_group_param]);
  const moveParamDetails = (index) => {
    setIsDisplayNewAddOption(false);
    let paramDetails = parameterDataTable;
    paramDetails.splice(index, 1);
    setParameterDataTable(paramDetails);
    setTimeout(() => {
      setIsDisplayNewAddOption(true);
    }, 10);
  };
  const addNewParameterDetails = () => {
    let paramaData = customFormData[sectionIndex];
    paramaData.is_group_param_name = paramaData.is_group_param;
    if (
      !paramaData["smpl_set_paramjson"] &&
      !paramaData["smpl_set_groupjson"]
    ) {
      let errLabel = "";
      errLabel = "Groups / Parameters";
      toast.error(errLabel + "  required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (paramaData.is_group_param === "Group") {
      let isTestMethosErr = false;
      let isBasisErr = false;
      groupParameteres.some((param, gpIndex) => {
        var testMethodiId = paramaData["smpl_set_testmethodjson_" + gpIndex];
        var basisIdselect = paramaData["smpl_set_basisjson_" + gpIndex];
        if (testMethodiId === undefined || testMethodiId === "") {
          toast.error("All Test Methods are required", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          isTestMethosErr = true;
          return true;
        } else if (basisIdselect === undefined || basisIdselect.length === 0) {
          toast.error("All Basis are required", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          isBasisErr = true;
          return true;
        }
      });
      if (isBasisErr || isTestMethosErr) {
        return true;
      }
      for (let obj in paramaData) {
        if (
          ![
            "smpl_set_paramjson",
            "smpl_set_unit",
            "smpl_set_paramjson_name",
            "smpl_set_groupjson",
            "smpl_set_groupjson_name",
            "smpl_set_smpljson_name",
            "smpl_set_basisjson_name",
            "smpl_set_testmethodjson_name",
            "smpl_set_basisjson",
            "smpl_set_testmethodjson",
          ].includes(obj) &&
          (paramaData[obj] === undefined || !paramaData[obj].length)
        ) {
          const field = section.rows[0].filter((field, index) => {
            if (field.name === obj) {
              field.label = section.headers[index]
                ? section.headers[index].label
                : obj;
              return true;
            }
            // return false;
          });
          let errLabel = field.length ? field[0].errorlabel : obj;
          if (obj == "smpl_set_smpljson") {
            errLabel = "Samples";
          }
          toast.error(errLabel + "  required", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }
      }
    } else {
      // commented by DC
      for (let obj in paramaData) {
        if (
          ![
            "smpl_set_paramjson",
            "smpl_set_paramjson_name",
            "smpl_set_groupjson",
            "smpl_set_groupjson_name",
            "smpl_set_smpljson_name",
            "smpl_set_basisjson_name",
            "smpl_set_testmethodjson_name",
          ].includes(obj) &&
          (paramaData[obj] === undefined || !paramaData[obj].length)
        ) {
          const field = section.rows[0].filter((field, index) => {
            if (field.name === obj) {
              field.label = section.headers[index]
                ? section.headers[index].label
                : obj;
              return true;
            }
            // return false;
          });
          let errLabel = field.length ? field[0].errorlabel : obj;
          if (obj == "smpl_set_smpljson") {
            errLabel = "Samples";
          }
          toast.error(errLabel + "  required", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }
      }
    }
    paramaData.smpl_set_groupjson_name = getSelectedOptionName(
      [],
      updatedMasterOptions,
      "smpl_set_groupjson",
      paramaData["smpl_set_groupjson"],
      "smpl_set_groupjson",
      1
    );
    paramaData.smpl_set_paramjson_name = getSelectedOptionName(
      [],
      updatedMasterOptions,
      "smpl_set_paramjson",
      paramaData["smpl_set_paramjson"],
      "smpl_set_paramjson",
      1
    );

    paramaData.smpl_set_basisjson_name = getSelectedOptionName(
      [],
      updatedMasterOptions,
      "smpl_set_basisjson",
      paramaData["smpl_set_basisjson"],
      "smpl_set_basisjson"
    );
    paramaData.smpl_set_testmethodjson_name = getSelectedOptionName(
      [],
      updatedMasterOptions,
      "smpl_set_testmethodjson",
      paramaData["smpl_set_testmethodjson"],
      "smpl_set_testmethodjson",
      1
    );
    if (paramaData.is_group_param === "Group") {
      let parameters = [];
      groupParameteres.map((param, gpIndex) => {
        let standards = [];
        let basiss = [];
        var stdId = paramaData["smpl_set_testmethodjson_" + gpIndex];
        var stdName = getSelectedOptionName(
          [],
          updatedMasterOptions,
          "smpl_set_testmethodjson_" + gpIndex,
          paramaData["smpl_set_testmethodjson_" + gpIndex],
          "smpl_set_testmethodjson_" + gpIndex,
          1,
          1
        );
        standards.push({
          std_id: stdId,
          std_name: stdName[0],
        });
        // stdId.map((id, i) => {
        //   standards.push({
        //     std_id: id,
        //     std_name: stdName[0],
        //   });
        // });
        var basisId = paramaData["smpl_set_basisjson_" + gpIndex];
        var basisName = getSelectedOptionName(
          [],
          updatedMasterOptions,
          "smpl_set_basisjson_" + gpIndex,
          paramaData["smpl_set_basisjson_" + gpIndex],
          "smpl_set_basisjson_" + gpIndex,
          "",
          1
        );
        basisId.map((id, i) => {
          basiss.push({
            basis_id: id,
            basis_code: basisName[i],
          });
        });
        parameters.push({
          param_name: param.param_name,
          param_unit: paramaData["smpl_set_unit_" + gpIndex],
          param_id: param.param_id,
          standards: standards,
          basis: basiss,
        });
      });
      paramaData.groupJsonParameter = parameters;
    }
    const newArray = [...parameterDataTable, paramaData];
    console.log('newArraynewArray', newArray)
    setParameterDataTable(newArray);
    setGroupParameters([]);
    setCustomFormData(initialCustomData);
    setTimeout(() => {
      setCustomFormData((prevData) => {
        return {
          ...prevData,
          [sectionIndex]: {
            ...prevData[sectionIndex],
            smpl_set_smpljson: paramaData["smpl_set_smpljson"],
            smpl_set_smpljson_name: paramaData["smpl_set_smpljson_name"],
          },
        };
      });
    }, 10);
  };
  const addNewSampleSetDetails = () => {
    if (parameterDataTable.length == 0) {
      toast.error("Please Add at least 1 parameter Details", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    setIsSubmit(true);
    // createAssignmentHandler(parameterDataTable);
  };
  const openDeletePopup = () => {
    let headingMsg = "Confirmation!";
    let titleMsg = "Are you sure you want to submit?";
    return (
      <DeleteConfirmation
        isOpen={isSubmit}
        handleClose={setIsSubmit}
        handleConfirm={() => createAssignmentHandler(parameterDataTable)}
        popupMessage={titleMsg}
        popupHeading={headingMsg}
        isOverlayLoader={isOverlayLoader}
      />
    );
  };

  const onCustomChangeHandler = (indexNo, name, value) => {
    if (name == "smpl_set_paramjson" || name == "smpl_set_groupjson") {
      getGroupParameterMasterData(value);
    }
    setCustomFormData((prevData) => {
      return {
        ...prevData,
        [indexNo]: {
          ...prevData[indexNo],
          [name]: value,
        },
      };
    });
    if (name == "is_group_param") {
      setCustomFormData((prevData) => {
        return {
          ...prevData,
          [indexNo]: {
            ...prevData[indexNo],
            [name + "_name"]: value,
          },
        };
      });
    }
  };
  const getGroupParameterMasterData = async (value) => {
    setIsGroupChanged(false);
    setGroupParameters([]);
    try {
      setIsLoading(true);
      let tempBody = {
        lab_id: formData[1].jrf_lab,
        commodity_id: formData[1].jrf_commodity,
      };
      if (customFormData[sectionIndex].is_group_param == "Group") {
        tempBody.group_id = value;
      } else {
        tempBody.param_id = value;
      }
      let res = await postDataFromApi(labGroupsStdBasisApi, tempBody);
      if (res.data && res.data.status == 200) {
        const actualResponse = res.data.data;
        if (customFormData[sectionIndex].is_group_param == "Group") {
          setGroupParameters(actualResponse.parameters);
          actualResponse.parameters.map((groupParam, gpindex) => {
            let standards = groupParam.standard || [];
            let basis = groupParam.basis || [];
            standards = standards.filter((group) => {
              group.name = group.std_name;
              group.id = group.std_id;
              return true;
            });
            basis = basis.filter((group) => {
              group.name = group.basis_code;
              group.id = group.basis_id;
              return true;
            });
            const groupsData = {
              model: "smpl_set_testmethodjson_" + gpindex,
              data: standards,
            };
            const basisData = {
              model: "smpl_set_basisjson_" + gpindex,
              data: basis,
            };
            const unitspValue = groupParam.param_unit.split(',')
            let unitoptions = []
            unitspValue.map((opt) => {
              unitoptions.push({
                name: opt,
                id: opt
              })
            })
            const smlpUnits = {
              model: "smpl_set_unit_" + gpindex,
              data: unitoptions,
            };
            setCustomFormData((prevData) => {
              return {
                ...prevData,
                [sectionIndex]: {
                  ...prevData[sectionIndex],
                  ["smpl_set_basisjson_" + gpindex]:
                    basis.length == 1 ? [basis[0].id] : [],
                  ["smpl_set_basisjson_name"]:
                    basis.length == 1 ? [basis[0].basis_code] : [],
                  ["smpl_set_testmethodjson_" + gpindex]:
                    standards.length == 1 ? standards[0].id.toString() : "",
                  ["smpl_set_testmethodjson_name"]:
                    standards.length == 1 ? standards[0].std_name : "",
                  ["smpl_set_unit_" + gpindex]:
                    unitspValue[0],
                },
              };
            });
            setUpdatedMasterOptions((prev) => [...prev, groupsData, basisData, smlpUnits]);
          });
        } else {
          let standards = actualResponse.standard || [];
          let basis = actualResponse.basis || [];
          standards = standards.filter((group) => {
            group.name = group.std_name;
            group.id = group.std_id;
            return true;
          });
          basis = basis.filter((group) => {
            group.name = group.basis_code;
            group.id = group.basis_id;
            return true;
          });
          const groupsData = {
            model: "smpl_set_testmethodjson",
            data: standards,
          };
          const basisData = {
            model: "smpl_set_basisjson",
            data: basis,
          };
          const unitspValue = actualResponse.param_unit.split(',')
          let unitoptions = []
          unitspValue.map((opt) => {
            unitoptions.push({
              name: opt,
              id: opt
            })
          })
          const smlpUnits = {
            model: "smpl_set_unit",
            data: unitoptions,
          };
          setCustomFormData((prevData) => {
            return {
              ...prevData,
              [sectionIndex]: {
                ...prevData[sectionIndex],
                ["smpl_set_basisjson"]: basis.length == 1 ? [basis[0].id] : [],
                ["smpl_set_basisjson_name"]:
                  basis.length == 1 ? [basis[0].basis_code] : [],
                ["smpl_set_testmethodjson"]:
                  standards.length == 1 ? standards[0].id.toString() : "",
                ["smpl_set_testmethodjson_name"]:
                  standards.length == 1 ? standards[0].std_name : "",
                ["smpl_set_unit"]:
                unitspValue[0],
              },
            };
          });
          setUpdatedMasterOptions((prev) => [...prev, groupsData, basisData, smlpUnits]);
        }
      }
    } catch (error) { }
    setTimeout(() => {
      setIsGroupChanged(true);
      setIsLoading(false);
    }, [10]);
  };
  const createAssignmentHandler = async (parameterData) => {
    let smpl_set_smpljson = [];
    let smpl_set_paramjson = [];
    let smpl_set_groupjson = [];
    parameterData.map((paramData, seqIndex) => {
      paramData.smpl_set_smpljson.map((sample) => {
        if (!smpl_set_smpljson.includes(sample)) {
          smpl_set_smpljson.push(sample);
        }
      });
      let basis = [];
      let basiscodes = [];
      if (paramData.smpl_set_basisjson_name.length > 0) {
        basiscodes = paramData.smpl_set_basisjson_name.split(",");
      } else {
        basiscodes = [];
      }
      paramData.smpl_set_basisjson.map((basId, i) => {
        basis.push({
          basis_id: basId,
          basis_code: basiscodes[i],
        });
      });
      if (paramData["is_group_param"] == "Group") {
        let parameters = [];
        // groupParameteres.map((param)=>{
        //   parameters.push({
        //     param_name:"",
        //     standards:[
        //       {
        //         "std_id": 30,
        //         "std_name": "IS 1760 (Part-2) RA 2022"
        //       },
        //     ],
        //     basis:[
        //       {
        //       "basis_id": 2,
        //       "basis_code": "ARB"
        //       }
        //     ]
        //   })
        // })
        smpl_set_groupjson.push({
          group_id: paramData.smpl_set_groupjson,
          group_name: paramData.smpl_set_groupjson_name,
          parameters: paramData.groupJsonParameter,
          // std_id: paramData.smpl_set_testmethodjson,
          // std_name: paramData.smpl_set_testmethodjson_name,
          // basis: basis,
          sequanceNo: seqIndex,
        });
      } else {
        smpl_set_paramjson.push({
          param_id: paramData.smpl_set_paramjson,
          param_name: paramData.smpl_set_paramjson_name,
          std_id: paramData.smpl_set_testmethodjson,
          std_name: paramData.smpl_set_testmethodjson_name,
          basis: basis,
          sequanceNo: seqIndex,
          param_unit: paramData.smpl_set_unit,
        });
      }
    });
    const newMainPayload = {
      sample_inward_id: formData[1]?.sampleInwardIdMain,
      sample_set_data: {
        smpl_set_smpljson: smpl_set_smpljson,
        smpl_set_groupjson: smpl_set_groupjson,
        smpl_set_testmethodjson: [],
        smpl_set_basisjson: [],
        smpl_set_paramjson: smpl_set_paramjson,
      },
    };
    // return;
    setIsLoading(true);
    setIsOverlayLoader(true)
    const res = await postDataFromApi(sampleDetailsAPI, newMainPayload);
    if (res.data.status === 200) {
      getInwardTabledata(formData[1]?.sampleInwardIdMain);
      setParameterDataTable([]);
      setCustomFormData(initialCustomData);
      setIsSubmit(false);
      setIsOverlayLoader(false)
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsOverlayLoader(false)
    setIsLoading(false);
  };
  const getInwardTabledata = async (simpleId) => {
    let payload = {
      smpl_inwrd_id: simpleId,
    };
    let res = await postDataFromApi(sampleInwardDetailsGetAPI, payload);
    if (res.data.status === 200) {
      const updatedFormData = { ...formData };
      let selectedSimpleIds = [];
      res.data.data.sample_set_data.forEach((singleInwardData, i) => {
        singleInwardData.smpl_set_smpljson.map((simpleId) => {
          selectedSimpleIds.push(simpleId);
        });

        if (!updatedFormData[sectionIndex]) {
          updatedFormData[sectionIndex] = {};
        }
        section.rows.forEach((row) => {
          row.forEach((columnName) => {
            const fieldName = `${columnName.name}_${i}`;
            const value = singleInwardData[columnName.name];
            updatedFormData[sectionIndex][fieldName] = value;
          });
        });
      });
      const newArray = res.data.data.sample_set_data;
      let FinalCombinedArray = [];
      newArray.map((singleParamaSet) => {
        let combinedArray = [];
        for (const [key, value] of Object.entries(
          singleParamaSet.smpl_set_groupjson
        )) {
          combinedArray.push({ ...value });
        }
        for (const [key, value] of Object.entries(
          singleParamaSet.smpl_set_paramjson
        )) {
          combinedArray.push({ ...value });
        }
        combinedArray = combinedArray.sort((a, b) => a.sequanceNo - b.sequanceNo);
        FinalCombinedArray.push(combinedArray)
      });
      setFinalParamDataSort(FinalCombinedArray)
      setSampleDataTable(newArray);
      setTableData(newArray);
      updatedFormData[0]["smpl_status"] = res.data.data.smpl_status;
      updatedFormData[0]["smpl_inwrd_No"] = res.data.data.smpl_inward_number;
      updatedFormData[0]["inward_msfm_number"] =
        res.data.data.inward_msfm_number;
      setFormData(updatedFormData);
      setSelectedOptions(selectedSimpleIds);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      getSampleOptionData();
    }, 1000);
  }, [masterOptions, selectedOptions]);
  const getSampleOptionData = () => {
    let simplaMasterData = masterOptions?.find((model, index) => {
      if (model.model === "smpl_set_smpljson") {
        return model;
      }
    });
    if (simplaMasterData) {
      let notSelectedOptions = simplaMasterData?.data.filter((simpleId) => {
        return !selectedOptions.includes(simpleId.name);
      });

      let filterData = masterOptions.filter((model) => {
        //Already implemented in multiple option comonent so i hv commented this
        // if (model.model == "smpl_set_smpljson") {
        //   model.data = notSelectedOptions;
        // }
        return true;
      });
      // newMasterOptions = filterData;
      if (notSelectedOptions.length == 0) {
        setIsDisplayNewAddOption(false);
      } else {
        setIsDisplayNewAddOption(true);
      }
      setUpdatedMasterOptions(filterData);
      return;
    }
    setUpdatedMasterOptions(masterOptions);
  };
  const onActionHandleClick = async (actionSelected) => {
    assignmentPageHandleAction(
      actionSelected,
      tableData,
      simpleInwardId,
      setSaveClicked,
      getInwardTabledata,
      setEditableIndex,
      popupIndex,
      getSampleIdsMasterData,
      setIsOverlayLoader
    );
  };
  const removeGroupParameter = (gpindex) => {
    if (gpindex > -1) {
      groupParameteres.splice(gpindex, 1);
    }
    setIsLoading(true);
    const updatedFormData = { ...customFormData };
    delete updatedFormData[1]["smpl_set_basisjson_" + groupParameteres.length];
    delete updatedFormData[1][
      "smpl_set_testmethodjson_" + groupParameteres.length
    ];
    console.log("updatedFormDataupdatedFormData", updatedFormData);
    setCustomFormData(updatedFormData);
    setTimeout(() => {
      groupParameteres.map((groupParam, gpindex) => {
        let standards = groupParam.standard || [];
        let basis = groupParam.basis || [];
        standards = standards.filter((group) => {
          group.name = group.std_name;
          group.id = group.std_id;
          return true;
        });
        basis = basis.filter((group) => {
          group.name = group.basis_code;
          group.id = group.basis_id;
          return true;
        });
        const groupsData = {
          model: "smpl_set_testmethodjson_" + gpindex,
          data: standards,
        };
        const basisData = {
          model: "smpl_set_basisjson_" + gpindex,
          data: basis,
        };
        setCustomFormData((prevData) => {
          return {
            ...prevData,
            [sectionIndex]: {
              ...prevData[sectionIndex],
              ["smpl_set_basisjson_" + gpindex]:
                basis.length == 1 ? [basis[0].id] : [],
              ["smpl_set_basisjson_name"]:
                basis.length == 1 ? [basis[0].basis_code] : [],
              ["smpl_set_testmethodjson_" + gpindex]:
                standards.length == 1 ? standards[0].id.toString() : "",
              ["smpl_set_testmethodjson_name"]:
                standards.length == 1 ? standards[0].std_name : "",
            },
          };
        });
        setUpdatedMasterOptions((prev) => [...prev, groupsData, basisData]);
      });
      setGroupParameters(groupParameteres);
      setIsLoading(false);
    }, 10);
  };
  const getGroupParameterElement = (cell, rowIndex, cellIndex, type) => {
    if (!isGroupChanged) {
      return;
    }
    if (type !== "deleteIcon") {
      cell.customOptions = [];
    }
    let elementData = [];
    elementData = groupParameteres.map((groupParam, gpindex) => {
      if (type != "standard" && type !== "deleteIcon") {
        cell.multiple = true;
      }

      return type === "deleteIcon" ? (
        groupParameteres.length > 1 && (
          <div className="actionOptions deleteGroupParameter">
            <button
              type="button"
              onClick={() => removeGroupParameter(gpindex)}
              className="invisible-button"
              aria-label={"Delete"}
            >
              <i className={"bi bi-trash3-fill"} title={"Delete"}></i>
            </button>
          </div>
        )
      ) : (
        <div className={type === "basis" ? "assignmentGroupParameter" : ""}>
          <RenderFields
            field={cell}
            sectionIndex={sectionIndex}
            fieldIndex={rowIndex * 100 + cellIndex}
            formData={customFormData}
            handleFieldChange={onCustomChangeHandler}
            formErrors={formErrors} // Pass formErrors to RenderFields
            ///for render table only
            renderTable={true}
            tableIndex={rowIndex}
            customName={cell.name + "_" + gpindex}
            masterOptions={updatedMasterOptions}
            from="Table"
          />
        </div>
      );
    });
    return elementData;
  };
  const getSelectedOptions = (name) => {
    let selectedIds = [];
    parameterDataTable.map((singleParam) => {
      if (singleParam[name]) {
        selectedIds.push(singleParam[name]);
      }
    });
    return selectedIds;
  };
  return (
    <div key={sectionIndex} className="row my-2">
      {sampleDataTable.length > 0 && (
        <Card className="Scrollable">
          <CardBody>
            <CardTitle tag="h5">{section.title}</CardTitle>
            <table className="table table-white responsive borderless no-wrap align-middle renderTable">
              <thead>
                <tr>
                  <th>Set No.</th>
                  <th>Samples</th>
                  <th>Type</th>
                  {section.headers.map((header, headerIndex) =>
                    header.name != "is_group_param" ? (
                      ["smpl_set_testmethodjson"].includes(header.name) ? (
                        <>
                          <th key={"groups-header" + headerIndex}>Parameter</th>
                          <th key={"groups-header" + headerIndex}>
                            {header.label}
                          </th>
                        </>
                      ) : (
                        <th key={"groups-header" + headerIndex}>
                          {header.label}
                        </th>
                      )
                    ) : null
                  )}
                  {action != "View" ? <th>Action</th> : null}
                </tr>
              </thead>
              <tbody>
                {sampleDataTable.map((singleParamDetails, setIndex) => (
                  <React.Fragment key={"setIndex" + setIndex}>
                    {singleParamDetails.smpl_set_paramjson.map(
                      (paramData, rowIndex) => {
                        let groupCount = 0;
                        singleParamDetails.smpl_set_groupjson.map(
                          (singleGroup) => {
                            groupCount += singleGroup.parameters.length;
                          }
                        );
                        return section.rows.map((row, rowIndex2) => (
                          <tr
                            key={"rowIndex" + rowIndex}
                            className="border-top"
                          >
                            {rowIndex == 0 && (
                              <React.Fragment>
                                <td
                                  rowSpan={
                                    singleParamDetails.smpl_set_paramjson
                                      .length +
                                    singleParamDetails.smpl_set_groupjson
                                      .length +
                                    groupCount
                                  }
                                >
                                  {setIndex + 1}
                                </td>
                                <td
                                  rowSpan={
                                    singleParamDetails.smpl_set_paramjson
                                      .length +
                                    singleParamDetails.smpl_set_groupjson
                                      .length +
                                    groupCount
                                  }
                                >
                                  {Array.isArray(
                                    singleParamDetails["smpl_set_smpljson"]
                                  )
                                    ? getSelectedOptionName(
                                      [],
                                      masterOptions,
                                      "smpl_set_smpljson",
                                      singleParamDetails["smpl_set_smpljson"],
                                      "smpl_set_smpljson"
                                    )
                                    : singleParamDetails["smpl_set_smpljson"]}
                                </td>
                              </React.Fragment>
                            )}
                            <td>Parameter</td>
                            {row.map((cell, cellIndex) =>
                              cell.subname != "group_id" &&
                                cell.subname != "isGroup" ? (
                                ["smpl_set_testmethodjson"].includes(
                                  cell.name
                                ) ? (
                                  <>
                                    <td>--</td>
                                    <td key={"cellIndex" + cellIndex}>
                                      {Array.isArray(
                                        singleParamDetails["smpl_set_smpljson"]
                                      ) && cell.subname == "basis"
                                        ? paramData[cell.subname].map(
                                          (basecode, index) =>
                                            (index != 0 ? "," : "") +
                                            basecode.basis_code
                                        )
                                        : paramData[cell.subname]}
                                    </td>
                                  </>
                                ) : (
                                  <td key={"cellIndex" + cellIndex}>
                                    {Array.isArray(
                                      singleParamDetails["smpl_set_smpljson"]
                                    ) && cell.subname == "basis"
                                      ? paramData[cell.subname].map(
                                        (basecode, index) =>
                                          (index != 0 ? "," : "") +
                                          basecode.basis_code
                                      )
                                      : paramData[cell.subname]}
                                  </td>
                                )
                              ) : null
                            )}
                            {rowIndex == 0 && action != "View" && (
                              <td
                                rowSpan={
                                  singleParamDetails.smpl_set_paramjson.length +
                                  singleParamDetails.smpl_set_groupjson.length +
                                  groupCount
                                }
                              >
                                <div className="actionColumn">

                                  <ActionOptionsTable
                                    actions={
                                      (editableIndex === 0 &&
                                        setIndex === 0) ||
                                        editableIndex === setIndex
                                        ? EditAction
                                        : editableIndex === 0 || editableIndex
                                          ? []
                                          : MainAction
                                    }
                                    onActionHandleClick={onActionHandleClick}
                                    setPopupIndex={setPopupIndex}
                                    useFor="Edit"
                                    editableIndex={editableIndex}
                                    popupIndex={popupIndex}
                                    popupMessages={popupMessages}
                                    saveClicked={saveClicked}
                                    tableIndex={setIndex}
                                    isCustomSave={0}
                                    setEditableIndex={setEditableIndex}
                                    getInwardTabledata={getInwardTabledata}
                                    simpleInwardId={simpleInwardId}
                                    moduleType={moduleType}
                                  />

                                </div>
                              </td>
                            )}
                          </tr>
                        ));
                      }
                    )}
                    {singleParamDetails.smpl_set_groupjson.map(
                      (paramData, rowIndex) => {
                        let groupCount = 0;
                        if (rowIndex == 0) {
                          singleParamDetails.smpl_set_groupjson.map(
                            (singleGroup) => {
                              groupCount += singleGroup.parameters.length;
                            }
                          );
                        }

                        return section.rows.map((row, rowIndex2) => (
                          <>
                            <tr
                              key={"rowIndex" + rowIndex}
                              className="border-top"
                            >
                              {rowIndex == 0 &&
                                singleParamDetails.smpl_set_paramjson.length ==
                                0 && (
                                  <React.Fragment>
                                    <td
                                      rowSpan={
                                        singleParamDetails.smpl_set_paramjson
                                          .length +
                                        singleParamDetails.smpl_set_groupjson
                                          .length +
                                        groupCount
                                      }
                                    >
                                      {setIndex + 1}
                                    </td>
                                    <td
                                      rowSpan={
                                        singleParamDetails.smpl_set_paramjson
                                          .length +
                                        singleParamDetails.smpl_set_groupjson
                                          .length +
                                        groupCount
                                      }
                                    >
                                      {Array.isArray(
                                        singleParamDetails["smpl_set_smpljson"]
                                      )
                                        ? getSelectedOptionName(
                                          [],
                                          masterOptions,
                                          "smpl_set_smpljson",
                                          singleParamDetails[
                                          "smpl_set_smpljson"
                                          ],
                                          "smpl_set_smpljson"
                                        )
                                        : singleParamDetails[
                                        "smpl_set_smpljson"
                                        ]}
                                    </td>
                                  </React.Fragment>
                                )}
                              <td rowSpan={paramData.parameters.length + 1}>
                                Group
                              </td>
                              <td rowSpan={paramData.parameters.length + 1}>
                                {paramData["group_name"]}
                              </td>
                            </tr>
                            {paramData.parameters.map((basecode, index) => (
                              <tr>
                                {row.map((cell, cellIndex) =>
                                  cell.subname != "group_id" &&
                                    cell.subname != "isGroup" &&
                                    cell.subname != "param_name" ? (
                                    ["smpl_set_testmethodjson"].includes(
                                      cell.name
                                    ) ? (
                                      <>
                                        <td>{basecode.param_name}</td>
                                        <td key={"cellIndex" + cellIndex}>
                                          {basecode.standards.map(
                                            (std, i) =>
                                              (i != 0 ? "," : "") + std.std_name
                                          )}
                                        </td>
                                      </>
                                    ) : cell.name === "smpl_set_unit" ? <td>{basecode.param_unit}</td> : (
                                      <td key={"cellIndex" + cellIndex}>
                                        {Array.isArray(
                                          singleParamDetails[
                                          "smpl_set_smpljson"
                                          ]
                                        ) &&
                                          (cell.subname == "basis" ||
                                            cell.subname == "std_name")
                                          ? cell.subname == "basis"
                                            ? basecode.basis.map(
                                              (base, i) =>
                                                (i != 0 ? "," : "") +
                                                base.basis_code
                                            )
                                            : // : basecode.standards.std_name
                                            basecode.standards.map(
                                              (std, i) =>
                                                (i != 0 ? "," : "") +
                                                std.std_name
                                            )
                                          : cell.subname == "param_name"
                                            ? paramData["group_name"]
                                            : paramData[cell.subname]}
                                      </td>
                                    )
                                  ) : null
                                )}
                                {rowIndex == 0 &&
                                  index == 0 &&
                                  singleParamDetails.smpl_set_paramjson
                                    .length == 0 && action != "View" && (
                                    <td
                                      rowSpan={
                                        singleParamDetails.smpl_set_paramjson
                                          .length +
                                        (singleParamDetails.smpl_set_groupjson
                                          .length - 1) +
                                        groupCount
                                      }
                                    >
                                      <div className="actionColumn">
                                        <ActionOptionsTable
                                          actions={
                                            (editableIndex === 0 &&
                                              setIndex === 0) ||
                                              editableIndex === setIndex
                                              ? EditAction
                                              : editableIndex === 0 ||
                                                editableIndex
                                                ? []
                                                : MainAction
                                          }
                                          onActionHandleClick={
                                            onActionHandleClick
                                          }
                                          setPopupIndex={setPopupIndex}
                                          useFor="Edit"
                                          editableIndex={editableIndex}
                                          popupIndex={popupIndex}
                                          popupMessages={popupMessages}
                                          saveClicked={saveClicked}
                                          tableIndex={setIndex}
                                          isCustomSave={0}
                                          setEditableIndex={setEditableIndex}
                                          getInwardTabledata={
                                            getInwardTabledata
                                          }
                                          simpleInwardId={simpleInwardId}
                                          moduleType={moduleType}
                                        />
                                      </div>
                                    </td>
                                  )}
                              </tr>
                            ))}
                          </>
                        ));
                      }
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </CardBody>
        </Card>
      )}
      {isDisplayNewAddOption &&
        moduleType !== "sampleverification" &&
        action != "View" && (
          <Card
            className="Scrollable addNewSetData"
            style={{ position: "relative" }}
          >
            <CardBody>
              <CardTitle tag="h5">{section.title}</CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                {/* Overview of the projects */}
              </CardSubtitle>
              {/* <div className="form-group my-2 mt-3"> */}
              {isLoading && <Loader />}
              {/* <label htmlFor="samples" style={{ width: `${6}%` }}>
                  Samples
                </label> */}

              <RenderFields
                field={{
                  width: 4,
                  name: "smpl_set_smpljson",
                  label: "Samples",
                  type: "select",
                  options: [],
                  required: true,
                  fieldWidth: 100,
                  multiple: true,
                }}
                sectionIndex={sectionIndex}
                fieldIndex={1 * 100 + 1}
                formData={customFormData}
                handleFieldChange={onCustomChangeHandler}
                formErrors={formErrors} // Pass formErrors to RenderFields
                ///for render table only
                renderTable={true}
                tableIndex={sectionIndex}
                customName={""}
                masterOptions={updatedMasterOptions}
                exludeOptions={selectedOptions}
              // from="Table"
              />
              {/* </div> */}
              <table className="table table-white responsive borderless no-wrap align-middle renderTable">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    {section.headers.map((header, headerIndex) =>
                      ["smpl_set_testmethodjson"].includes(header.name) ? (
                        <>
                          <th
                            key={"headerIndex" + headerIndex}
                            className={section.rows[0]}
                          >
                            Parameter
                          </th>
                          <th key={"headerIndex" + headerIndex}>
                            {header.label}
                          </th>
                        </>
                      ) : (
                        <th key={"headerIndex" + headerIndex}>
                          {header.label}
                        </th>
                      )
                    )}
                    {action != "View" ? <th>Action</th> : null}
                  </tr>
                </thead>
                <tbody>
                  {parameterDataTable.map((paramData, rowIndex) =>
                    section.rows.map((row, rowIndex2) =>
                      paramData.is_group_param == "Group" ? (
                        <>
                          <tr
                            key={"rowIndex" + rowIndex}
                            className="border-top"
                          >
                            <td
                              rowSpan={paramData.groupJsonParameter.length + 1}
                            >
                              {rowIndex + 1}
                            </td>
                            {row.map((cell, cellIndex) =>
                              cell.name == "smpl_set_paramjson" ||
                                cell.name == "smpl_set_groupjson" ? (
                                (cell.name == "smpl_set_groupjson" &&
                                  paramData.is_group_param == "Group") ||
                                  (cell.name == "smpl_set_paramjson" &&
                                    paramData.is_group_param == "Parameter") ? (
                                  <td
                                    key={"cellIndex" + cellIndex}
                                    rowSpan={
                                      paramData.groupJsonParameter.length + 1
                                    }
                                  >
                                    {paramData[cell.name + "_name"]}
                                  </td>
                                ) : null
                              ) : [
                                "smpl_set_testmethodjson",
                                "smpl_set_basisjson",
                                "smpl_set_unit",
                              ].includes(cell.name) ? null : (
                                <td
                                  key={"cellIndex" + cellIndex}
                                  rowSpan={
                                    paramData.groupJsonParameter.length + 1
                                  }
                                >
                                  {paramData[cell.name + "_name"]}
                                </td>
                              )
                            )}
                          </tr>
                          {paramData.groupJsonParameter.map(
                            (groupParam, gpIndex) => (
                              <tr
                                key={"rowIndex" + rowIndex}
                                className="border-top"
                              >
                                {row.map((cell, cellIndex) =>
                                  [
                                    "smpl_set_testmethodjson",
                                    "smpl_set_basisjson",
                                    "smpl_set_unit",
                                  ].includes(cell.name) ? (
                                    ["smpl_set_testmethodjson"].includes(
                                      cell.name
                                    ) ? (
                                      <>
                                        <td key={"cellIndex" + cellIndex}>
                                          {groupParam.param_name}
                                        </td>
                                        <td key={"cellIndex" + cellIndex}>
                                          {groupParam.standards.map(
                                            (std, i) =>
                                              (i != 0 ? "," : "") + std.std_name
                                          )}
                                        </td>
                                      </>
                                    ) : cell.name === "smpl_set_unit" ? <td key={"cellIndex" + cellIndex}>
                                      {paramData[cell.name + '_' + gpIndex]}
                                    </td> : (
                                      <td key={"cellIndex" + cellIndex}>
                                        {groupParam.basis.map(
                                          (base, i) =>
                                            (i != 0 ? "," : "") +
                                            base.basis_code
                                        )}
                                      </td>
                                    )
                                  ) : null
                                )}
                                {gpIndex == 0 && (
                                  <td
                                    rowSpan={
                                      paramData.groupJsonParameter.length
                                    }
                                  >
                                    <div className="actionColumn">
                                      {action != "View" ? (
                                        <div className="actionOptions">
                                          <button
                                            type="button"
                                            onClick={() => {
                                              moveParamDetails(rowIndex);
                                            }}
                                            className="invisible-button"
                                            aria-label={"Delete"}
                                          >
                                            <i
                                              className={"bi bi-trash3-fill"}
                                              title={"Delete"}
                                            ></i>
                                          </button>
                                        </div>
                                      ) : null}
                                    </div>
                                  </td>
                                )}
                              </tr>
                            )
                          )}
                        </>
                      ) : (
                        <tr key={"rowIndex" + rowIndex} className="border-top">
                          <td>{rowIndex + 1}</td>
                          {row.map((cell, cellIndex) =>
                            cell.name == "smpl_set_paramjson" ||
                              cell.name == "smpl_set_groupjson" ? (
                              (cell.name == "smpl_set_groupjson" &&
                                paramData.is_group_param == "Group") ||
                                (cell.name == "smpl_set_paramjson" &&
                                  paramData.is_group_param == "Parameter") ? (
                                <td key={"cellIndex" + cellIndex}>
                                  {paramData[cell.name + "_name"]}
                                </td>
                              ) : null
                            ) : cell.name == "smpl_set_unit" ? <td key={"cellIndex" + cellIndex}>
                              {paramData[cell.name]}
                            </td> : paramData.is_group_param == "Group" &&
                              [
                                "smpl_set_testmethodjson",
                                "smpl_set_basisjson",
                              ].includes(cell.name) ? (
                              ["smpl_set_testmethodjson"].includes(
                                cell.name
                              ) ? (
                                <>
                                  <td key={"cellIndex" + cellIndex}>
                                    {paramData.groupJsonParameter.map(
                                      (param) => param.param_name
                                    )}
                                  </td>
                                  <td key={"cellIndex" + cellIndex}>
                                    {paramData.groupJsonParameter.map(
                                      (param, index) =>
                                        param.standards.map(
                                          (std, i) =>
                                            (index != 0 || i != 0 ? "," : "") +
                                            std.std_name
                                        )
                                    )}
                                  </td>
                                </>
                              ) : (
                                <td key={"cellIndex" + cellIndex}>
                                  {paramData.groupJsonParameter.map(
                                    (param, index) =>
                                      param.basis.map(
                                        (base, i) =>
                                          (index != 0 || i != 0 ? "," : "") +
                                          base.basis_code
                                      )
                                  )}
                                </td>
                              )
                            ) : ["smpl_set_testmethodjson"].includes(
                              cell.name
                            ) ? (
                              <>
                                <td key={"cellIndex" + cellIndex}>--</td>
                                <td key={"cellIndex" + cellIndex}>
                                  {paramData[cell.name + "_name"]}
                                </td>
                              </>
                            ) : (
                              <td key={"cellIndex" + cellIndex}>
                                {paramData[cell.name + "_name"]}
                              </td>
                            )
                          )}
                          <td>
                            <div className="actionColumn">
                              {action != "View" ? (
                                <div className="actionOptions">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      moveParamDetails(rowIndex);
                                    }}
                                    className="invisible-button"
                                    aria-label={"Delete"}
                                  >
                                    <i
                                      className={"bi bi-trash3-fill"}
                                      title={"Delete"}
                                    ></i>
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      )
                    )
                  )}
                  {section.rows.map((row, rowIndex) => (
                    <tr key={"rowIndex" + rowIndex} className="border-top">
                      <td>{rowIndex + 1 + parameterDataTable.length}</td>
                      {row.map((cell, cellIndex) => {
                        return cell.name == "smpl_set_paramjson" ||
                          cell.name == "smpl_set_groupjson" ? (
                          (cell.name == "smpl_set_groupjson" &&
                            customFormData[sectionIndex].is_group_param ==
                            "Group") ||
                            (cell.name == "smpl_set_paramjson" &&
                              customFormData[sectionIndex].is_group_param ==
                              "Parameter") ? (
                            <td
                              key={"cellIndex" + cellIndex}
                              className={
                                cell.type === "radio" ? "radio_adjust" : null
                              }
                            >
                              <RenderFields
                                field={cell}
                                sectionIndex={sectionIndex}
                                fieldIndex={rowIndex * 100 + cellIndex}
                                formData={customFormData}
                                handleFieldChange={onCustomChangeHandler}
                                formErrors={formErrors} // Pass formErrors to RenderFields
                                ///for render table only
                                renderTable={true}
                                tableIndex={rowIndex}
                                customName={cell.name}
                                masterOptions={updatedMasterOptions}
                                from="Table"
                                exludeOptions={getSelectedOptions(cell.name)}
                              />
                            </td>
                          ) : null
                        ) : !(
                          customFormData[sectionIndex].is_group_param ==
                          "Group" &&
                          [
                            "smpl_set_testmethodjson",
                            "smpl_set_basisjson",
                            'smpl_set_unit'
                          ].includes(cell.name)
                        ) ? (
                          ["smpl_set_testmethodjson"].includes(cell.name) ? (
                            <>
                              <td>-</td>
                              <td
                                key={"cellIndex" + cellIndex}
                                className={
                                  cell.type === "radio" ? "radio_adjust" : null
                                }
                              >
                                {" "}
                                <RenderFields
                                  field={cell}
                                  sectionIndex={sectionIndex}
                                  fieldIndex={rowIndex * 100 + cellIndex}
                                  formData={customFormData}
                                  handleFieldChange={onCustomChangeHandler}
                                  formErrors={formErrors} // Pass formErrors to RenderFields
                                  ///for render table only
                                  renderTable={true}
                                  tableIndex={rowIndex}
                                  customName={cell.name}
                                  masterOptions={updatedMasterOptions}
                                  from="Table"
                                />
                              </td>
                            </>
                          ) : (
                            <td
                              key={"cellIndex" + cellIndex}
                              className={
                                cell.type === "radio" ? "radio_adjust" : null
                              }
                            >
                              {" "}
                              <RenderFields
                                field={cell}
                                sectionIndex={sectionIndex}
                                fieldIndex={rowIndex * 100 + cellIndex}
                                formData={customFormData}
                                handleFieldChange={onCustomChangeHandler}
                                formErrors={formErrors} // Pass formErrors to RenderFields
                                ///for render table only
                                renderTable={true}
                                tableIndex={rowIndex}
                                customName={cell.name}
                                masterOptions={updatedMasterOptions}
                                from="Table"
                              />
                            </td>
                          )
                        ) : ["smpl_set_testmethodjson"].includes(cell.name) ? (
                          <>
                            <td>
                              {groupParameteres.map((groupParam, gpindex) => (
                                <RenderFields
                                  field={{
                                    name: "smpl_param",
                                    subname: "smpl_param",
                                    type: "label",
                                    required: true,
                                    fieldWidth: 100,
                                    errorlabel: "Test Method",
                                    isStaticValue: true,
                                    value: groupParam.param_name,
                                  }}
                                  sectionIndex={sectionIndex}
                                  fieldIndex={rowIndex * 100 + gpindex}
                                  formData={customFormData}
                                  handleFieldChange={onCustomChangeHandler}
                                  formErrors={formErrors} // Pass formErrors to RenderFields
                                  ///for render table only
                                  renderTable={true}
                                  tableIndex={rowIndex}
                                  customName={"smpl_param_" + gpindex}
                                  masterOptions={updatedMasterOptions}
                                  from="Table"
                                />
                              ))}
                            </td>
                            <td>
                              {getGroupParameterElement(
                                cell,
                                rowIndex,
                                cellIndex,
                                "standard"
                              )}
                            </td>
                          </>
                        ) : ["smpl_set_unit"].includes(cell.name) ? <td>
                          {groupParameteres.map((groupParam, gpindex) => (
                            <RenderFields
                              field={{
                                name: "smpl_set_unit",
                                subname: "smpl_set_unit",
                                type: "select",
                                required: true,
                                fieldWidth: 100,
                                errorlabel: "Test Method",
                              }}
                              sectionIndex={sectionIndex}
                              fieldIndex={rowIndex * 100 + gpindex}
                              formData={customFormData}
                              handleFieldChange={onCustomChangeHandler}
                              formErrors={formErrors} // Pass formErrors to RenderFields
                              ///for render table only
                              renderTable={true}
                              tableIndex={rowIndex}
                              customName={"smpl_set_unit_" + gpindex}
                              masterOptions={updatedMasterOptions}
                              from="Table"
                            />
                          ))}
                        </td> : (
                          <td className={"groupBasisTd"}>
                            {getGroupParameterElement(
                              cell,
                              rowIndex,
                              cellIndex,
                              "basis"
                            )}
                          </td>
                        );
                      })}
                      <td>
                        {customFormData[sectionIndex].is_group_param ==
                          "Group" &&
                          getGroupParameterElement("", "", "", "deleteIcon")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="button_container">
                <button
                  type="button"
                  className="submitBtn btn btn-primary"
                  onClick={() => {
                    addNewSampleSetDetails();
                  }}
                >
                  Submit Set
                </button>
                <button
                  type="button"
                  className="submitBtn btn btn-primary"
                  onClick={() => {
                    addNewParameterDetails();
                  }}
                >
                  <i class="bi bi-plus-circle customAdd"></i> Add
                </button>
              </div>
              {isSubmit && openDeletePopup()}
            </CardBody>
          </Card>
        )}
    </div>
  );
};

RenderAssignmentTableSection.propTypes = {
  section: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  formData: PropTypes.object.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  addRow: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  deleteColumn: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  popupMessages: PropTypes.array.isRequired,
  pageType: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  masterOptions: PropTypes.object.isRequired,
  saveClicked: PropTypes.bool.isRequired,
  moduleType: PropTypes.string.isRequired,
  setTableData: PropTypes.func.isRequired,
  getAssignmentMasterData: PropTypes.func.isRequired,
  setSaveClicked: PropTypes.func.isRequired,
  tableData: PropTypes.array.isRequired,
  getSampleIdsMasterData: PropTypes.func.isRequired,
};
export default RenderAssignmentTableSection;
