import React, { useEffect, useState } from "react";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";

import "react-phone-number-input/style.css";

const InputPhone = ({ field }) => {
  const {
    name,
    label,
    value,
    onChange,
    required,
    error,
    placeholder,
    readOnly,
    tooltip,
    characterLimit,
    pattern,
    actionClicked,
  } = field;



  const [errorMsg, setErrorMsg] = useState(false);
  const [mobileValue, setmobileValue] = useState(value || " ");
  useEffect(() => {
    const regex = new RegExp(pattern);
    if (mobileValue !== "") {
      if (regex.test(mobileValue)) {
        setErrorMsg(false);
      } else {
        setErrorMsg(true);
      }
    } else {
      setErrorMsg(false);
    }
  }, [mobileValue]);

  useEffect(() => {
   
    onChange(mobileValue);
  }, [mobileValue]);

  return (
    <div className="form-group my-2">
      {label && (
        <label htmlFor={name} style={{ width: `${18}%` }}>
          {label}
          <span className="required_mark"> {required ? ` *` : null}</span>
        </label>
      )}
      <div className="w-75 d-inline-block mx-2">
        {/* <input
          type="number"
          onInput={(e) => {
            if (e.target.value.length > 10) {
              e.target.value = e.target.value.slice(0, 10);
            }
          }}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          className="form-control rounded-2"
          placeholder={placeholder}
          readOnly={readOnly}
          title={tooltip}
          maxLength={10}
          pattern={pattern}
        /> */}
        <PhoneInput
          placeholder="Enter phone number"
          id={name}
          name={name}
          defaultCountry="IN"
          value={mobileValue}
          onChange={setmobileValue}
          required={required}
          className="form-control rounded-2"
          readOnly={readOnly}
          title={tooltip}
        />
        {/* Value : {mobileValue && formatPhoneNumberIntl(mobileValue || "89")} */}
        {/* International: {mobileValue && formatPhoneNumberIntl(mobileValue)}
        <br />
        Is valid: {mobileValue && isValidPhoneNumber(mobileValue) ? "true" : "false"} */}
       
        {error && actionClicked && <p className="text-danger">{error}</p>}
      </div>
    </div>
  );
};

export default InputPhone;
