import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  handleChecklistBtns
} from "../commonHandlerFunction/sampleInwardHandlerFunctions";
import React from "react";
import PropTypes from 'prop-types';


const ViewCheckListButtons = ({
  remarkText,
  setIsPopupOpen,
  setJRFCreationType,
  setInwardBtnchange,
  formData,
  setSaveClicked,
  formConfig,
  saveClicked,
  setIsRejectPopupOpen,
  viewOnly,
  handleBackButtonFunction,
  setIsOverlayLoader
}) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const translate = t;
  return (
    <div className="submit_btns">
      {(<React.Fragment>
        <Button
          type="button"
          className="cancelBtn"
          id="submit_btn3"
          onClick={() => {
            handleBackButtonFunction()
          }}
        >
          {translate("common.backBtn")}
        </Button>
        {/* <button
          type="button"
          className="saveBtn"
          id="submit_btn2"
          data-name="save"
          onClick={(e) =>
            handleChecklistBtns(
              e,
              "save",
              formData,
              remarkText,
              setSaveClicked,
              formConfig,
              navigate
            )
          }
          // disabled={saveClicked}
        >
          {translate("common.saveBtn")}
        </button> */}
        <Button
          type="button"
          className="rejectBtn"
          id="submit_btn1"
          onClick={(e) => setIsRejectPopupOpen(true)}
          // disabled={saveClicked}
        >
          {translate("common.rejectBtn")}
        </Button>
        <Button
          type="button"
          className="submitBtn"
          id="submit_btn1"
          onClick={(e) =>
            handleChecklistBtns(
              e,
              "accept",
              formData,
              remarkText,
              setSaveClicked,
              formConfig,
              navigate,
              setIsOverlayLoader
            )
          }
          // disabled={saveClicked}
        >
          {translate("common.acceptBtn")}
        </Button>
      </React.Fragment>)}
    </div>
  );
};


ViewCheckListButtons.propTypes = {
  remarkText: PropTypes.string.isRequired,
  setIsPopupOpen: PropTypes.func.isRequired,
  setJRFCreationType: PropTypes.func.isRequired,
  setInwardBtnchange: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setSaveClicked: PropTypes.func.isRequired,
  formConfig: PropTypes.object.isRequired,
  saveClicked: PropTypes.bool.isRequired,
  setIsRejectPopupOpen: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool.isRequired,
};

export default ViewCheckListButtons;
